<template>
  <v-container v-if="detailArticle.author?.profile">
    <v-card light>
      <v-card-actions>
        <v-list-item class="grow">
          <v-list-item-avatar color="grey darken-3">
            <v-img
              class="elevation-6"
              alt=""
              :src="getImage(detailArticle.author?.profile.avatar)"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="`${detailArticle.author?.first_name} ${detailArticle.author?.last_name}`"
            />
            <v-list-item-subtitle
              v-text="formatDate(detailArticle.pub_date)"
            />
          </v-list-item-content>
        </v-list-item>
      </v-card-actions>
      <v-card-title class="text-md-h4" v-text="detailArticle.title"/>
    </v-card>
    <v-card light>
      <v-card-text>
        <v-img :src="getImage(detailArticle.image)" max-height="500" class="mb-5" contain/>
        <div v-html="detailArticle.text" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import getImage from '@/common/mixins/getImage';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

export default {
  name: 'Article',
  mixins: [getImage],
  computed: {
    ...mapState('articles', ['detailArticle']),
  },
  watch: {
    '$route.params.id': {
      async handler(id) {
        if (id) {
          await this.get(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions('articles', ['get']),
    formatDate(date) {
      return dayjs(date).locale('ru').format('DD MMMM YYYY, hh:mm');
    },
  },
};
</script>

<style scoped>

</style>
